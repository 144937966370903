<template>
    <div>
        <header-portrate />

        
    <div class="m-2" style="margin-bottom:70px !important;">
        <bread-crumbs :items="items" />
        <tabs-comp :tabs="tabs" />

        <v-row :style="`direction:`+lang.dir">
            <v-col
            cols="12"
            md="4"
            sm="12">
            <b-input-group class="mt-3">
                <b-form-input placeholder="Start Date" style="background:#eee" type="date" v-model="sdate"></b-form-input>
                <b-form-input placeholder="End Date" style="background:#eee" type="date" v-model="edate"></b-form-input>
                <b-input-group-append style="border-left:1px solid #CCC">
                    <b-button variant="light" style="background: #ccc !important;font-size:14px;color:#000;font-size:14px;padding:8px 22px;border-radius:5px" @click="getReport()"><i class="fas fa-search" style="color:000;font-weight:bold;"></i></b-button>
                </b-input-group-append>
            </b-input-group>
            </v-col>
            <v-col cols="12" md="4" sm="12" class="text-right">
                <b-button variant="light" style="width:145px;margin-right:9px" class="mytab mytab1 mt-3" @click="exportE('e')">{{lang.export_excel}}</b-button>
                <b-button variant="light" style="width:145px;margin-right:3px;background:red;color:#FFF;display:none" class="mytab mt-3" @click="exportE('p')">PDF تصدير</b-button>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12" sm="12">
                <div style="display:flex;flex-direction: row;"><v-simple-checkbox v-model="removeZero" /> اخفاء الحسابات الصفرية</div>
            </v-col>
            <v-col cols="12" md="12" sm="12">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th colspan="9" class="text-center backBlack">{{lang.account_balance_report}}</th>
                        </tr>
                        <tr>
                            <th class="text-center backBlack"></th>
                            <th class="text-center backBlack"></th>
                            <th class="text-center backBlack" colspan="2">{{lang.openingbalance}}</th>
                            <th class="text-center backBlack" colspan="2">{{lang.transaction}}</th>
                            <th class="text-center backBlack" colspan="2">{{lang.balance}}</th>
                            <th class="text-center backBlack"></th>
                        </tr>
                        <tr>
                            <th class="text-center backBlack">{{lang.account}}</th>
                            <th class="text-center backBlack">{{lang.description}}</th>
                            <th class="text-center backBlack">{{lang.depit}}</th>
                            <th class="text-center backBlack">{{lang.credit}}</th>
                            <th class="text-center backBlack">{{lang.depit}}</th>
                            <th class="text-center backBlack">{{lang.credit}}</th>
                            <th class="text-center backBlack">{{lang.depit}}</th>
                            <th class="text-center backBlack">{{lang.credit}}</th>
                            <th class="text-center backBlack">{{lang.vlaue}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in accountrowses" :key="index">
                            <td class="text-center" :style="`direction:ltr;${item.style}`">{{item.classid}}</td>
                            <td class="text-center" :style="`direction:ltr;${item.style}`">{{item[`name`+lang.langname]}}</td>
                            <td class="text-center" :style="`direction:ltr;${item.style}`">{{$convertToComa($RoundNums(item.oDebit,2),true)}}</td>
                            <td class="text-center" :style="`direction:ltr;${item.style}`">{{$convertToComa($RoundNums(item.oCredit,2),true)}}</td>
                            <td class="text-center" :style="`direction:ltr;${item.style}`">{{$convertToComa($RoundNums(item.Debit,2),true)}}</td>
                            <td class="text-center" :style="`direction:ltr;${item.style}`">{{$convertToComa($RoundNums(item.Credit,2),true)}}</td>
                            <td class="text-center" :style="`direction:ltr;${item.style}`">{{$convertToComa($RoundNums(item.bDebit,2),true)}}</td>
                            <td class="text-center" :style="`direction:ltr;${item.style}`">{{$convertToComa($RoundNums(item.bCredit,2),true)}}</td>
                            <td class="text-center" :style="`direction:ltr;${item.style}`">{{item.Balance}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th class="backEmpty"></th>
                            <th class="backEmpty"></th>
                            <th class="backGreen">{{ $convertToComa($RoundNums(totals.oDebit,2),true) }}</th>
                            <th class="backRed">{{ $convertToComa($RoundNums(totals.oCredit,2),true) }}</th>
                            <th class="backGreen">{{ $convertToComa($RoundNums(totals.Debit,2),true) }}</th>
                            <th class="backRed">{{ $convertToComa($RoundNums(totals.Credit,2),true) }}</th>
                            <th class="backGreen">{{ $convertToComa($RoundNums(totals.bDebit,2),true) }}</th>
                            <th class="backRed">{{ $convertToComa($RoundNums(totals.bCredit,2),true) }}</th>
                            <th class="backEmpty"></th>
                        </tr>
                        <tr>
                            <th colspan="9" class="backGreen" v-if="btotals == 0"> {{lang.balance}} : {{btotals}} </th>
                            <th colspan="9" class="backRed" v-if="btotals != 0"> {{lang.balance}} : {{btotals}} </th>
                        </tr>
                    </tfoot>
                </v-simple-table>
            </v-col>
        </v-row>
        <Footer />
    </div>
    </div>
</template>

<script>
import breadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import axios from 'axios'
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
export default {
  components: { breadCrumbs ,TabsComp, HeaderPortrate,Footer},
    data()
     {
        return {
            sdate: new Date().toISOString().substring(0, 10),
            edate: new Date().toISOString().substring(0, 10),
            accountrows: [],
            accounts: [],
            account: 0,
            account_name: '',
            removeZero: true,
            branchid: 0,
        }
    },
    computed: {
        totals: function(){
            let t = {
                oDebit: 0,
                oCredit: 0,
                Debit: 0,
                Credit: 0,
                bDebit: 0,
                bCredit: 0,
            }
            for(let i=0;i<this.accountrowses.length;i++){
                if(this.accountrowses[i].type == 2)
                {
                    t.oDebit = parseFloat(t.oDebit) + parseFloat(this.accountrowses[i].oDebit);
                    t.oCredit = parseFloat(t.oCredit) + parseFloat(this.accountrowses[i].oCredit);
                    t.Debit = parseFloat(t.Debit) + parseFloat(this.accountrowses[i].Debit);
                    t.Credit = parseFloat(t.Credit) + parseFloat(this.accountrowses[i].Credit);
                    t.bDebit = parseFloat(t.bDebit) + parseFloat(this.accountrowses[i].bDebit);
                    t.bCredit = parseFloat(t.bCredit) + parseFloat(this.accountrowses[i].bCredit);
                }
            }
            return t;
        },
        accountrowses: function(){
            let t = [];
            for(const item in this.accountrows){
                if(this.removeZero == true){
                    if(
                        parseFloat(this.accountrows[item].bDebit) != 0 ||
                        parseFloat(this.accountrows[item].bCredit) != 0
                    ){
                        t.push(this.accountrows[item])
                    }
                }else{
                    t.push(this.accountrows[item])
                }
            }
            return this.accountrows;
        },
        btotals: function(){
            let t = 0;
            for(let i=0;i<this.accountrows.length;i++){
                if(this.accountrowses[i].type == 2)
                {
                    t = parseFloat(t) + parseFloat(this.accountrows[i].bDebit) - parseFloat(this.accountrows[i].bCredit);
                }
            }
            t = this.$RoundNum(t);
            if(t < 0){
                return "("+parseFloat(t)*-1+")";
            }else{
                return t;
            }
        },
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        items: function(){
            return {
                    text: this.lang.account_statement,
                    disabled: true,
                    to: '/reports/statment-report',
                }
        },
        tabs: function() {
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
                const cM = localStorage.getItem('currentMenu');
                if(value.main.index == cM){
                    cMenu = value.sub;
                }else if(cM == null && value.main.index == 1){
                    cMenu = value.sub;
                }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
        headers: function(){
            return '';
        }
    },
    created() {
    //    this.getReport();
       this.getRep();
    },
    methods: {
        getRep(){
            const post = new FormData();
            post.append('type',"getPNLeport");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[sdate]',this.sdate);
            post.append('data[edate]',this.edate);
            post.append('data[account]',this.account);
            post.append('data[branchid]',this.branchid);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                if(response && response.data && response.data.results){
                    this.accountrows = response.data.results.data;
                }
            })
        },
        getAccounts(){
            this.getRep();
            // const post = new FormData();
            // post.append('type',"getAccoutns");
            // post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            // post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            // post.append('data[sdate]',this.sdate);
            // post.append('data[edate]',this.edate);
            // axios.post(
            //     this.$store.state.SAMCOTEC.r_path , post
            // ).then((response) => {
            //     const res = response.data;
            //     // // console.log(res);
            //     this.accounts = res.results.data;
            // })
        },  
        getReport(){
            const post = new FormData();
            post.append('type',"getBalanceReport");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[sdate]',this.sdate);
            post.append('data[edate]',this.edate);
            post.append('data[account]',this.account);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                if(typeof res.results.data !== 'undefined')
                {
                    this.accountrows = res.results.data;
                }
            })
        }
    },
}
</script>